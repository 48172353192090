<!-- 帮助反馈 -->
<template>
  <div class="help">
    <van-field v-model="feedback.username" placeholder="请输入您的姓名" />
    <van-field
      v-model="feedback.phone"
      type="number"
      placeholder="请留下您的手机号，方便我们联系您"
    />
    <textarea
      class="help1"
      v-model="feedback.content"
      rows="2"
      placeholder="请留下您的宝贵意见，我们将努力改进(内容不少于10个字)"
    />
    <van-button @click="submit">提交反馈</van-button>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      feedback: {
        username: "",
        phone: "",
        content: "",
        userid: "",
      },
    };
  },

  methods: {
    submit(e) {
      var that = this;
      var data = this.feedback;
      if (!data.username || data.username == "") {
        Toast.fail("请填写姓名");
        return;
      }
      if (
        !data.phone ||
        data.phone == "" ||
        !/^1[3456789]\d{9}$/.test(data.phone)
      ) {
        Toast.fail("请输入11位国内手机号");
        return;
      }
      if (!data.content || data.content == "" || data.content.length < 10) {
        if (!data.content || data.content == "") {
          Toast.fail("请填写反馈内容");
          return;
        }
        if (data.content.length < 10) {
          Toast.fail("反馈内容最少写10个字");
          return;
        }
      }
      let postData2 = {
        username: that.feedback.username,
        phone: that.feedback.phone,
        content: that.feedback.content,
        userid: sessionStorage.getItem("userid"),
      };

      this.$http
        .post("/api/comment/feedback", postData2)
        .then((res) => {
          if (res.status == 200) {
            Toast.fail({
              message: "提交成功",
              duration: 500,
              onClose: () => {
                this.$router.push({
                  name: "personalCenter",
                });
              },
            });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.help {
  width: 100%;
  height: 100%;
}
.van-field {
  width: 9rem;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.help1 {
  height: 200px;
  line-height: 20px;
  resize: none;
  text-align: left;
  font-size: 0.4rem;
  width: 9rem;
  margin: auto;
  border-radius: 10px;
  padding: 15px;
}
.van-button {
  width: 9rem;
  margin: auto;
  background-color: #00d48b;
  font-size: 0.5rem;
  margin-top: 20px;
  color: #ffffff;
  border-radius: 10px;
}
</style>
